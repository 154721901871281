@tailwind base;
@tailwind components;
@tailwind utilities;


.image-container {
    overflow: hidden;
    white-space: nowrap;
}

.image-container div {
    display: inline-block;
    /* width: 100%; Adjust width as needed */
    height: auto;
    margin-right: 10px; /* Space between images */
    animation: moveImages 15s linear infinite;
}

@keyframes moveImages {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-1900%);
    }
}



  
  @keyframes moveLeft {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }


  .language-slider-animation {
    animation: moveLeft 30s linear infinite;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
  
  
  

.container {
  width: 300px; /* Adjust width as needed */
  height: 200px; /* Adjust height as needed */
  border: 2px solid #ccc;
  border-radius: 50%;
  position: relative;
}

.half-circle {
  position: absolute;
  bottom: -1px; /* Adjust based on your border width */
  left: 0;
  width: 100%;
  height: 30px; /* Adjust height as needed */
  background-color: #fff; /* Change background color as needed */
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top: none; /* Remove top border */
  border: 2px solid #ccc; /* Adjust border color and width as needed */
  border-top: none;
}